import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
	Button,
	TextField,
	Dialog,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	makeStyles,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Grid,
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { toast } from "react-toastify";
import { red } from "@material-ui/core/colors";
import { format } from "date-fns";
import { DateRange, Message, Person } from "@material-ui/icons";
import ModalBuscaChamados from "../ModalBuscaChamado";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
	dialogPaper: {
		minWidth: '50vw',
		maxWidth: '50vw',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

const filterOptions = createFilterOptions({
	trim: true,
});

const ChamadoModal = ({ modalOpen, onClose, ticketid, ticketWhatsappId }) => {
	const history = useHistory();
	const [selectedCliente, setSelectedCliente] = useState(null);
	const [clientes_options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [ticketChamadoVinculado, setTicketChamadoVinculado] = useState(null);
	const [operadorData, setOperadorData] = useState(null);
	const [selectedOperador, setSelectedOperador] = useState(null);
	const [operadorPadrao, setOperadorPadrao] = useState(null);
	const [statusChamadoData, setStatusChamadoData] = useState(null);
	const [selectedStatusChamado, setSelectedStatusChamado] = useState(null);
	const [origemData, setOrigemData] = useState(null);
	const [selectedOrigem, setSelectedOrigem] = useState(null);
	const [selectedSolicitante, setSelectedSolicitante] = useState("");
	const [prioridadeData, setPrioridadeData] = useState(null);
	const [selectedPrioridade, setSelectedPrioridade] = useState(null);
	const [categoriaData, setCategoriaData] = useState(null);
	const [selectedCategoria, setSelectedCategoria] = useState(null);
	const [colorStatusSelected, setColorStatusSelected] = useState('');
	const [selectedNovocomentario, setSelectedNovocomentario] = useState('');
	const [primeiroComentario, setSelectedPrimeiroComentario] = useState(null);
	const [comentariosData, setComentariosData] = useState(null);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [ModalBuscaChamadosOpen, setModalBuscaChamadosOpen] = useState(false);

	const classes = useStyles();

	useEffect(() => {
		const fetchChamadoData = async () => {
			try {
				const response = await api.get(`/chamados/${ticketid}`);

				setOperadorData(response.data.operadores);
				setOperadorPadrao(response.data.operadorpadrao.id_operador);
				setSelectedOperador(response.data.operadorpadrao.id_operador);
				setStatusChamadoData(response.data.status_chamado);
				setOrigemData(response.data.origem);
				setPrioridadeData(response.data.prioridades);
				setCategoriaData(response.data.categorias);
				setComentariosData(response.data.comentarios);
				setSelectedPrimeiroComentario(response.data.primeirocomentario);

				var clienteArray = "";
				if (response.data.cliente_chamado.id) {
					clienteArray = Array.isArray(response.data.cliente_chamado) ? response.data.cliente_chamado[0] : response.data.cliente_chamado;
					setSelectedCliente(clienteArray);
				}
				if (response.data.ticket_chamado_vinculado.id !== undefined) {
					setTicketChamadoVinculado(response.data.dados_chamado);
				} else {
					setTicketChamadoVinculado(null);
				}

				setIsDataLoaded(true);
			} catch (err) {
				console.error(err);
			}
		};

		fetchChamadoData();
	}, [ticketid]);

	useEffect(() => {
		if (ticketChamadoVinculado) {
			populaChamadoVinculado(ticketChamadoVinculado);
		}
	}, [ticketChamadoVinculado]);

	useEffect(() => {
		if (isDataLoaded) {
			if (ticketChamadoVinculado !== null) {
				populaChamadoVinculado(ticketChamadoVinculado);
			} else {
				populaChamadoVinculado(null);
			}
		}
	}, [isDataLoaded, ticketChamadoVinculado]);

	const populaChamadoVinculado = async (dados_chamado) => {

		if (dados_chamado && dados_chamado[0]?.id) {
			setSelectedOperador(dados_chamado[0].id_usuario_operador);
			setSelectedStatusChamado(dados_chamado[0].id_statuschamado);
			setSelectedOrigem(dados_chamado[0].id_origem_venda);
			setSelectedSolicitante(dados_chamado[0].sn_solicitante);
			setSelectedPrioridade(dados_chamado[0].sn_prioridade);
			setSelectedCategoria(dados_chamado[0].id_categoriachamados);

			const selectedStatus = statusChamadoData?.find(status => status.id === dados_chamado[0].id_statuschamado);
			if (selectedStatus) {
				setColorStatusSelected(selectedStatus.sn_cor);
			} else {
				setColorStatusSelected('');
			}
		} else {
			setSelectedOperador(null);
			setSelectedStatusChamado(null);
			setSelectedOrigem(null);
			setSelectedSolicitante('');
			setSelectedPrioridade(null);
			setSelectedCategoria(null);
			setColorStatusSelected('');
		}
	};

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchClientes = async () => {
				try {
					const { data } = await api.get("/clientes/", {
						params: { searchParam },
					});

					const clientesArray = Array.isArray(data.clientes) ? data.clientes : [];
					setOptions(clientesArray);
					setLoading(false);
				} catch (err) {
					setLoading(false);

				}
			};

			fetchClientes();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		if (!ticketChamadoVinculado) {
			setSearchParam("");
			setSelectedStatusChamado(null);
			setSelectedOrigem(null);
			setSelectedCategoria(null);
			setSelectedPrioridade(null);
			setSelectedOperador(operadorPadrao);
			setSelectedSolicitante("");
			setSelectedPrimeiroComentario(null);
			setComentariosData(null);
			setColorStatusSelected('');
		}
	};

	const handleSaveChamado = async (e) => {
		e.preventDefault();
		if (!ticketid) return;

		setLoading(true);
		try {
			let data = {};

			data.ticketid = ticketid;

			if (!selectedCliente || !selectedOperador || !selectedStatusChamado || !selectedOrigem || !selectedSolicitante || !selectedPrioridade || !selectedCategoria || !selectedNovocomentario) {
				toast.error("Preencha todos os campos obrigatórios.");
				setLoading(false);
				return;
			}

			data.clienteId = selectedCliente.id;
			data.operadorId = selectedOperador;
			data.statuschamadoId = selectedStatusChamado;
			data.origemId = selectedOrigem;
			data.solicitante = selectedSolicitante;
			data.prioridadeId = selectedPrioridade;
			data.categoriaId = selectedCategoria;
			data.novocomentario = selectedNovocomentario;

			const response = await api.post("/chamados", data);

			if (response.data.sucesso) {
				toast.success("Chamado criado com sucesso");

				setTimeout(() => {
					window.location.reload();
				}, 1000);

			} else {
				toast.error("Erro ao salvar vínculo");
			}
		} catch (error) {
			toast.error("Erro ao vincular o Ticket ao Chamado");
		}
		setLoading(false);
		setIsDataLoaded(true);
	};

	const handleUpdateChamado = async (e) => {
		e.preventDefault();
		if (!ticketChamadoVinculado[0].id) return;
		var chamadoid = ticketChamadoVinculado[0].id
		setLoading(true);
		try {
			let data = {};

			data.ticketid = ticketid;
			data.chamadoid = chamadoid

			if (selectedCliente) {
				data.clienteId = selectedCliente.id;
			} else {
				console.error("selectedCliente está vazio");
				return;
			}

			if (selectedOperador) {
				data.operadorId = selectedOperador;
			} else {
				console.error("selectedOperador está vazio");
				return;
			}

			if (selectedStatusChamado) {
				data.statuschamadoId = selectedStatusChamado;
			} else {
				console.error("selectedStatusChamado está vazio");
				return;
			}

			if (selectedOrigem) {
				data.origemId = selectedOrigem;
			} else {
				console.error("selectedOrigem está vazio");
				return;
			}

			if (selectedSolicitante) {
				data.solicitante = selectedSolicitante;
			} else {
				console.error("selectedSolicitante está vazio");
				return;
			}

			if (selectedPrioridade) {
				data.prioridadeId = selectedPrioridade;
			} else {
				console.error("selectedSolicitante está vazio");
				return;
			}

			if (selectedCategoria) {
				data.categoriaId = selectedCategoria;
			} else {
				console.error("selectedCategoria está vazio");
				return;
			}

			if (selectedNovocomentario) {
				data.novocomentario = selectedNovocomentario;
			}

			const response = await api.put(`/chamados/${chamadoid}`, data);

			if (response.data.sucesso === true) {

				toast.success("Chamado criado com sucesso");

				setTimeout(() => {
					window.location.reload();
				}, 800);

			} else {
				toast.error("Erro ao atualizar chamado");
			}
		} catch (error) {
			toast.error("Erro ao atualizar chamado 2 ");
		}
	};

	const handleDeleteVinculoChamado = async (ticketChamadoVinculado) => {
		setLoading(true);
		try {
			const retorno = await api.delete(`/chamados/${ticketChamadoVinculado[0].id}`);

			if (retorno.data.sucesso === true) {
				toast.success(i18n.t(retorno.data.msg || "Vínculo removido com sucesso"));
			} else {
				return;
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		handleClose();
		setTicketChamadoVinculado(null);
		setSearchParam("");
		setSelectedStatusChamado(null);
		setSelectedOrigem(null);
		setSelectedCategoria(null);
		setSelectedPrioridade(null);
		setSelectedOperador(operadorPadrao);
		setSelectedSolicitante("");
		setSelectedPrimeiroComentario(null);
		setComentariosData(null);
		setColorStatusSelected('');
		setSelectedCliente(null)
	};

	const handleStatusChange = async (e) => {
		const selectedStatusId = e.target.value;
		setSelectedStatusChamado(selectedStatusId);

		const selectedStatus = statusChamadoData.find(status => status.id === selectedStatusId);

		if (selectedStatus) {
			setColorStatusSelected(selectedStatus.sn_cor);
		} else {
			setColorStatusSelected('');
		}
	};

	const handleOpenModalBuscaChamado = () => {
		setModalBuscaChamadosOpen(true);
	};

	const handleCloseModalBuscaChamado = () => {
		setModalBuscaChamadosOpen(false);
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} classes={{ paper: classes.dialogPaper }} scroll="paper">
			<form onSubmit={!ticketChamadoVinculado ? handleSaveChamado : handleUpdateChamado}>
				<DialogTitle id="form-dialog-title">
					{i18n.t(!ticketChamadoVinculado ? "Novo Chamado" : "Alterar Chamado - " + ticketChamadoVinculado[0].id)}
				</DialogTitle>
				<hr style={{ height: '0.5px', border: 'none', backgroundColor: 'lightgrey' }} />
				<DialogContent>
					<div style={{ border: 'none', borderRadius: 15, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '20px', backgroundColor: '#fff', marginBottom: 20 }}>
						<Grid container spacing={3} style={{ marginBottom: 20 }}>
							<Grid item xs={6}>
								<Autocomplete
									style={{ width: '100%' }}
									getOptionLabel={(option) => `${option.id} - ${option.razao}`}
									onChange={(e, newValue) => {
										setSelectedCliente(newValue);
									}}
									options={clientes_options}
									filterOptions={filterOptions}
									value={selectedCliente || null}
									freeSolo
									autoHighlight
									noOptionsText={i18n.t("transferTicketModal.noOptions")}
									loading={loading}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Cliente"
											variant="outlined"
											autoFocus
											onChange={(e) => setSearchParam(e.target.value)}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{loading ? (
															<CircularProgress color="primary" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}

										/>
									)}
								/>
							</Grid>
							<Grid item xs={6}>
								<ButtonWithSpinner
									variant="contained"
									onClick={() => handleOpenModalBuscaChamado(ticketid)}
									color="primary"
									loading={loading}
									style={{ display: ticketChamadoVinculado ? 'none' : 'block' }}
								>
									{i18n.t("Buscar Chamado Existente")}
								</ButtonWithSpinner>
							</Grid>
						</Grid>

						<ModalBuscaChamados
							ModalBuscaChamadosOpen={ModalBuscaChamadosOpen}
							onClose={handleCloseModalBuscaChamado}
							ticketid={ticketid}
						/>

						<Grid container spacing={3} style={{ marginBottom: 20 }}>
							<Grid item xs={4}>
								<FormControl variant="outlined" className={classes.maxWidth}>
									<InputLabel>{i18n.t("Operador")}</InputLabel>
									<Select
										value={selectedOperador}
										onChange={(e) => setSelectedOperador(e.target.value)}
										label={i18n.t("Operador")}
									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{operadorData && operadorData.map((operador) => (
											<MenuItem key={operador.id} value={operador.id}>{operador.sn_descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant="outlined" className={classes.maxWidth} style={{ backgroundColor: colorStatusSelected, borderRadius: 5 }}>
									<InputLabel style={{ background: 'white', paddingBottom: '3px', paddingLeft: '7px', paddingRight: '7px', borderRadius: 20 }}>{i18n.t("Status")}</InputLabel>
									<Select
										value={selectedStatusChamado || ''}
										onChange={handleStatusChange}
										label={i18n.t("Status")}
									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{statusChamadoData && statusChamadoData.map((status) => (
											<MenuItem key={status.id} value={status.id} style={{ backgroundColor: status.sn_cor }}>
												{status.sn_descricao}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant="outlined" className={classes.maxWidth}>
									<InputLabel>{i18n.t("Origem")}</InputLabel>
									<Select
										value={selectedOrigem || ''}
										onChange={(e) => setSelectedOrigem(e.target.value)}
										label={i18n.t("Origem")}
									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{origemData && origemData.map((origem) => (
											<MenuItem key={origem.id} value={origem.id}>{origem.sn_descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container spacing={3} style={{ marginBottom: 20 }}>
							<Grid item xs={4}>
								<TextField
									name="Solicitante"
									style={{ width: '100%' }}
									label="Solicitante"
									variant="outlined"
									value={selectedSolicitante || ''}
									onChange={(e) => {
										setSelectedSolicitante(e.target.value);
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant="outlined" className={classes.maxWidth}>
									<InputLabel>{i18n.t("Prioridade")}</InputLabel>
									<Select
										value={selectedPrioridade || ''}
										onChange={(e) => setSelectedPrioridade(e.target.value)}
										label={i18n.t("Prioridade")}
									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{prioridadeData && prioridadeData.map((prioridade) => (
											<MenuItem key={prioridade.id} value={prioridade.id}>{prioridade.sn_descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant="outlined" className={classes.maxWidth}>
									<InputLabel>{i18n.t("Categoria")}</InputLabel>
									<Select
										value={selectedCategoria || ''}
										onChange={(e) => setSelectedCategoria(e.target.value)}
										label={i18n.t("Categoria")}
									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{categoriaData && categoriaData.map((categoria) => (
											<MenuItem key={categoria.id} value={categoria.id}>{categoria.sn_descricao}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						{primeiroComentario && (
							<Grid container spacing={2} style={{ marginBottom: 20 }}>
								<Grid item xs={12}>
									<FormControl variant="outlined" className={classes.maxWidth} style={{ marginBottom: 0 }}>
										<Grid container alignItems="stretch">
											<Grid item>
												<div
													style={{
														width: 56,
														height: '100%',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														backgroundColor: '#f0f0f0',
														borderRadius: '4px',
														border: '1px solid lightgrey',
														borderRight: 0,
														borderTopRightRadius: 0,
														borderBottomRightRadius: 0,
													}}
												>
													<Message />
												</div>
											</Grid>
											<Grid item xs>
												<TextField
													id="sn_comentarioprimeiro"
													value={primeiroComentario[0].sn_comentario}
													label="Primeiro Comentário"
													variant="outlined"
													multiline
													minRows={2}
													style={{ width: '100%' }}
												/>
											</Grid>
										</Grid>

										<Grid container spacing={1} justifyContent="flex-end">
											<Grid item xs={4}>
												<Grid container alignItems="center">
													<Grid item>
														<div style={{
															width: 40,
															height: 40,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															backgroundColor: '#f0f0f0',
															borderRadius: '4px',
															border: '1px solid lightgrey',
															borderRight: 0,
															borderTopRightRadius: 0,
															borderBottomRightRadius: 0,
														}}>
															<Person />
														</div>
													</Grid>
													<Grid item xs>
														<TextField
															id="sn_usuario"
															value={primeiroComentario[0].nome}
															InputProps={{ readOnly: true }}
															variant="outlined"
															style={{ width: '100%' }}
															size='small'
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={4}>
												<Grid container alignItems="center">
													<Grid item>
														<div style={{
															width: 40,
															height: 40,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															backgroundColor: '#f0f0f0',
															borderRadius: '4px',
															border: '1px solid lightgrey',
															borderRight: 0,
															borderTopRightRadius: 0,
															borderBottomRightRadius: 0,
														}}>
															<DateRange></DateRange>
														</div>
													</Grid>
													<Grid item xs>
														<TextField
															id="dt_cadastro"
															value={format(new Date(primeiroComentario[0].dt_cadastro), 'dd/MM/yyyy - HH:mm:ss')}
															InputProps={{ readOnly: true }}
															variant="outlined"
															style={{ width: '100%' }}
															size='small'
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</FormControl>
								</Grid>
							</Grid>
						)}
						<Grid container spacing={3} style={{}}>
							<Grid item xs={12}>
								<div className="row mt-3">
									<FormControl variant="outlined" className={classes.maxWidth}>
										<TextField
											id="novo_comentario"
											label="Novo Comentário"
											variant="outlined"
											multiline
											minRows={4}
											style={{ width: '100%' }}
											onChange={(e) => {
												setSelectedNovocomentario(e.target.value);
											}}
										/>
									</FormControl>
								</div>
							</Grid>
						</Grid>
					</div>

					{comentariosData && comentariosData.map((comentario) => (
						<div key={comentario.id} style={{ border: 'none', borderRadius: 15, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '20px', backgroundColor: '#fff', marginBottom: 20 }}>
							<Grid container spacing={2} style={{ marginBottom: 0 }}>
								<Grid item xs={12}>
									<FormControl variant="outlined" className={classes.maxWidth} style={{ marginBottom: 0 }}>
										<Grid container alignItems="stretch">
											<Grid item>
												<div
													style={{
														width: 56,
														height: '100%',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														backgroundColor: '#f0f0f0',
														borderRadius: '4px',
														border: '1px solid lightgrey',
														borderRight: 0,
														borderTopRightRadius: 0,
														borderBottomRightRadius: 0,
													}}
												>
													<Message />
												</div>
											</Grid>
											<Grid item xs>
												<TextField
													value={comentario.sn_comentario}
													label="Comentário"
													variant="outlined"
													multiline
													minRows={2}
													style={{ width: '100%' }}
												/>
											</Grid>
										</Grid>

										<Grid container spacing={1} justifyContent="flex-end">
											<Grid item xs={4}>
												<Grid container alignItems="center">
													<Grid item>
														<div style={{
															width: 40,
															height: 40,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															backgroundColor: '#f0f0f0',
															borderRadius: '4px',
															border: '1px solid lightgrey',
															borderRight: 0,
															borderTopRightRadius: 0,
															borderBottomRightRadius: 0,
														}}>
															<Person />
														</div>
													</Grid>
													<Grid item xs>
														<TextField
															id="sn_usuario"
															value={comentario.nome}
															InputProps={{ readOnly: true }}
															variant="outlined"
															style={{ width: '100%' }}
															size='small'
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={4}>
												<Grid container alignItems="center">
													<Grid item>
														<div style={{
															width: 40,
															height: 40,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															backgroundColor: '#f0f0f0',
															borderRadius: '4px',
															border: '1px solid lightgrey',
															borderRight: 0,
															borderTopRightRadius: 0,
															borderBottomRightRadius: 0,
														}}>
															<DateRange></DateRange>
														</div>
													</Grid>
													<Grid item xs>
														<TextField
															id="dt_cadastro"
															value={format(new Date(comentario.dt_cadastro), 'dd/MM/yyyy - HH:mm:ss')}
															InputProps={{ readOnly: true }}
															variant="outlined"
															style={{ width: '100%' }}
															size='small'
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</FormControl>
								</Grid>
							</Grid>
						</div>
					))}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("Fechar")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
						style={{ display: ticketChamadoVinculado ? 'none' : 'block' }}
					>
						{i18n.t("Salvar")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						variant="contained"
						onClick={() => handleDeleteVinculoChamado(ticketChamadoVinculado)}
						color="secondary"
						loading={loading}
						style={{ display: ticketChamadoVinculado ? 'block' : 'none' }}
					>
						{i18n.t("Desvincular")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
						style={{ display: ticketChamadoVinculado ? 'block' : 'none' }}
					>
						{i18n.t("Salvar")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog >
	);
};

export default ChamadoModal;