import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import { AuthContext } from "../../context/Auth/AuthContext";
import SelecionaFilaModal from "../SelecionaFilaModal";

const VcardPreview = ({ contact, numbers }) => {
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const [selectedContact, setContact] = useState({
        name: "",
        number: 0,
        profilePicUrl: ""
    });
    const [selecionarFilaModalOpen, setSelecionarFilaModalOpen] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    let contactObj = {
                        name: contact,
                        number: numbers !== undefined && numbers.replace(/\D/g, ""),
                        email: ""
                    };
                    const { data } = await api.post("/contact", contactObj);
                    setContact(data);
                } catch (err) {
                    console.log(err);
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [contact, numbers]);

    const handleNewChat = async () => {
        setSelecionarFilaModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelecionarFilaModalOpen(false);
    };

    return (
        <>
            <div style={{ minWidth: "250px" }}>
                <Grid container spacing={1} alignItems="center" style={{ paddingTop: "10px", paddingBottom: "10px", paddingRight: "" }}>
                    <Grid item xs={2}>
                        <Avatar style={{ marginLeft: "10px" }} src={selectedContact.profilePicUrl} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ marginLeft: "10px" }} variant="subtitle1" color="primary" gutterBottom>
                            {selectedContact.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            fullWidth
                            color="primary"
                            onClick={handleNewChat}
                            disabled={!selectedContact.number}
                        >
                            Conversar
                        </Button>
                    </Grid>
                </Grid>
            </div>

            <SelecionaFilaModal
                selecionarFilaModalOpen={selecionarFilaModalOpen}
                onClose={handleCloseModal}
                contactId={selectedContact.id}
                userId={user.id}
                status={"open"}
            />
        </>
    );
};

export default VcardPreview;
