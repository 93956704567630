import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import LayoutTicketCrm from "../layout/layoutCrm";

import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import TicketCrm from "../pages/TicketCrm/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import InicializarWhatsapp from "../pages/Login/Inicializarwhatsapp";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";

import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <WhatsAppsProvider>
          <Switch>
            {/* Rotas sem layout */}
            <Route exact path="/inicializarwhatsapp" component={InicializarWhatsapp} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />

            {/* Rotas com LoggedInLayout */}
            <Route exact path="/" component={Tickets} layout={LoggedInLayout} isPrivate />
            <Route exact path="/dashboard" component={Dashboard} layout={LoggedInLayout} isPrivate />
            <Route exact path="/tickets/:ticketId?" component={Tickets} layout={LoggedInLayout} isPrivate />
            <Route exact path="/connections" component={Connections} layout={LoggedInLayout} isPrivate />
            <Route exact path="/contacts" component={Contacts} layout={LoggedInLayout} isPrivate />
            <Route exact path="/users" component={Users} layout={LoggedInLayout} isPrivate />
            <Route exact path="/quickAnswers" component={QuickAnswers} layout={LoggedInLayout} isPrivate />
            <Route exact path="/Settings" component={Settings} layout={LoggedInLayout} isPrivate />
            <Route exact path="/Queues" component={Queues} layout={LoggedInLayout} isPrivate />

            {/* Rotas com LayoutTicketCrm */}
            <Route exact path="/ticketcrm/:ticketId?" component={TicketCrm} layout={LayoutTicketCrm} isPrivate />
          </Switch>
        </WhatsAppsProvider>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
