import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, CircularProgress, Box } from '@material-ui/core';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const InicializarWhatsapp = () => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    const authenticate = async () => {
      const getUrl = window.location.search;
      const urlParams = new URLSearchParams(getUrl);

      const sn_token = urlParams.get("token");
      const ticketcrm = urlParams.get("ticketcrm");

      if (sn_token) {
        if (!isAuth) {
          try {
            const { data } = await api.post("/auth/login", { sn_token, ticketcrm });

            localStorage.setItem("token", JSON.stringify(data.token));
            localStorage.setItem("empresaApelido", data.empresaApelido);
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            setUser(data.user);
            setIsAuth(true);
            toast.success(i18n.t("auth.toasts.success"));

            // Redirecionar ap�s login
            const redirectUrl = ticketcrm ? `/ticketcrm/${ticketcrm}` : data.url || "/";
            history.push(redirectUrl);
            setTimeout(() => {
              window.location.reload();
            }, 600);
          } catch (err) {
            console.error("Erro de login:", err);
          } finally {
            setLoading(false);
          }
        } else {
          const redirectUrl = ticketcrm ? `/ticketcrm/${ticketcrm}` : "/";
          history.push(redirectUrl);
          setTimeout(() => {
            window.location.reload();
          }, 600);
        }
      }
    };

    authenticate();
  }, [history, isAuth, setIsAuth, setUser]);

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress
            size={100}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container>

    </Container>
  );
};

export default InicializarWhatsapp;
